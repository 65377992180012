<template>
<div>
    <h1> {{ $t('trusted-by-teams-at') }}</h1>
    <hr class="mt-5">
    <img class="customser-logo" src="@/app/assets/img/tinkoff-logo-eng.svg">
    <img class="intaro-logo" src="@/app/assets/img/intaro_logo_1.svg">
    <img class="intaro-logo" src="@/app/assets/img/kt-kogo.svg">
    <img class="keycraft-logo" src="@/app/assets/img/key_craft_logo.svg">
    <img class="keycraft-logo" src="@/app/assets/img/samolet_logo_blue.svg">
    <img class="keycraft-logo" src="@/app/assets/img/rolf_logo.svg">
    <hr>
    <b-card-group deck>
    <b-card> <h2>600 000+</h2> {{$t('600k-proccess') }}</b-card>  <b-card> <h2>62 000+</h2> {{$t('62k-use') }}</b-card>
</b-card-group>
</div>
</template>

<script>
export default {
    name: 'FrontendTrustedBy',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style scoped>
.customser-logo {
    max-width: 150px; 
    

}

.intaro-logo {
    max-width: 90px;
    margin-left: 20px;
   
}

.keycraft-logo {
    margin-top: 7px;
    max-width: 90px;
    margin-left: 20px;
  
}
</style>
